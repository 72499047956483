import React from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

import { Link, useLocation, useResolvedPath } from "react-router-dom";

import mainLogo from "../assets/brand/brand.png";
import iconLogo from "../assets/brand/brand-small.png";

import { sidebarContents } from "../data/sidebarContents";
import { v4 as uuidv4 } from "uuid";

const Item = ({ title, to, icon, setCollapsed }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  let location = useLocation();
  let path = useResolvedPath(to, { relative: true });
  let match = false;

  if (to === "/") {
    match = location.pathname === path.pathname ? true : false;
  } else {
    let firstPath = path.pathname.split("/")[1];
    match = location.pathname.includes(firstPath);
  }

  const menuItemHandler = (setCollapsed) => {
    if (isSmallScreen) {
      setCollapsed(true);
    }
  };

  return (
    <MenuItem
      icon={icon}
      active={match}
      onClick={() => menuItemHandler(setCollapsed)}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

export const Sidebar = ({ collapsed, setCollapsed, menuClicked }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      onMouseOver={() => {
        if (!menuClicked) {
          setCollapsed(false);
        }
      }}
      onMouseOut={() => {
        if (!menuClicked) {
          setCollapsed(true);
        }
      }}
      sx={{
        "& .pro-sidebar.collapsed": {
          minWidth: isSmallScreen ? 0 : undefined,
          width: isSmallScreen ? 0 : undefined,
        },
        "& .pro-sidebar:not(.collapsed)": {
          width: "310px",
        },
        height: "100%",
        "& .pro-sidebar-layout": {
          position: "relative",
          overflowY: "hidden !important",
        },
        "& .pro-menu": {
          overflow: "auto",
        },
        "& .pro-sidebar-inner": {
          background: `${theme.palette.sidebar.background} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-menu-item": {
          color: `${theme.palette.sidebar.text} !important`,
        },
        "& .pro-inner-item:hover": {
          color: `${theme.palette.primary.light} !important`,
        },
        " & .pro-menu-item.active": {
          color: `${theme.palette.primary.main} !important`,
        },
        "& .pro-arrow-wrapper": {
          display: collapsed && "none",
        },
      }}
    >
      <ProSidebar collapsed={collapsed}>
        <Box
          className="brandLogo"
          width="100%"
          display="flex"
          justifyContent="center"
          py={3}
        >
          {collapsed && !isSmallScreen ? (
            <img alt="Logo" src={iconLogo} width="60%" />
          ) : (
            <img alt="Logo" src={mainLogo} width="60%" />
          )}
        </Box>

        <Menu iconShape="square">
          <Box pl={collapsed ? undefined : "10%"}>
            {sidebarContents.map((item) => {
              if (item.header) {
                if (!collapsed) {
                  return (
                    <Typography
                      key={uuidv4()}
                      variant="h6"
                      color={"#fcfcfc"}
                      sx={{
                        m: "15px 0 5px 20px",
                      }}
                    >
                      {item.header}
                    </Typography>
                  );
                }
              } else {
                return (
                  <Item
                    setCollapsed={setCollapsed}
                    key={uuidv4()}
                    title={item.title}
                    to={item.to}
                    icon={item.icon}
                  />
                );
              }

              return null;
            })}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};
